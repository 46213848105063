import isEmpty from 'lodash/isEmpty'
import React from 'react'
import CategoryPaletteCard from './CategoryPaletteCard'
import {StyledContainer} from './styles'
import Typography from '../../../common/Typography/Typography'

function CategoryPaletteContainer({state = {}, actions = {}}) {
  const {
    cards = [],
    sectionName = '',
    heading = '',
    subHeading = '',
    headingLevel = 2,
    subHeadingLevel = 4,
  } = state

  if (isEmpty(state) || !cards.length) {
    return null
  }

  return (
    <StyledContainer className="banners-container" data-section={sectionName}>
      <Typography
        text={heading}
        variant="heading-sm-bold"
        customClassName="heading-text"
        headingLevel={headingLevel}
        isDangerouslySet={true}
      />
      <div className="subheading-wrapper">
        <Typography
          text={subHeading}
          variant="body-base-regular"
          customClassName="subheading-text"
          headingLevel={subHeadingLevel}
          isDangerouslySet={true}
        />
      </div>
      <div className="cards-wrapper">
        {cards.map((card, idx) => {
          return (
            <CategoryPaletteCard state={card} actions={actions} key={idx} />
          )
        })}
      </div>
    </StyledContainer>
  )
}

export default CategoryPaletteContainer
