import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'
import React, {useCallback} from 'react'

import Responsive from '../../../../../HOC/Responsive'
import OptimizedImage from '../../../../../common/OptimizedImage'
import Typography from '../../../../../common/Typography/Typography'
import {StyledContainer} from './styles'

function MobileCategoryPaletteCard(props) {
  const {
    mobileImage,
    bgColor = '',
    categoryName = '',
    description = '',
    imageAlign = '',
    imageBgColor = '',
    cardShadow = '',
    cardBorderColor = '',
    headingLevel = 3,
    cta = {},
  } = props.state || {}
  const {handleCta = noop} = props.actions || {}

  const {src = '', height = '', width = ''} = mobileImage || {}

  const handleClick = useCallback(() => {
    handleCta(cta)
  }, [handleCta, cta])

  return (
    <StyledContainer
      bgColor={bgColor}
      imageBgColor={imageBgColor}
      cardShadow={cardShadow}
      imageAlign={imageAlign}
      cardBorderColor={cardBorderColor}
      onClick={handleClick}
    >
      <div className="image-container">
        <OptimizedImage
          source={src}
          aspectWidth={width || 120}
          aspectHeight={height || 120}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText="celeb-image"
          loading="lazy"
          customClassName="card-image"
        />
      </div>
      <div className="content-container">
        <div className="content-top">
          <Typography
            text={categoryName}
            variant="heading-sm-bold"
            customClassName="categoryName"
            headingLevel={headingLevel}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            className="font-awesome-icon"
          />
        </div>
        <div className="content-bottom">
          <Typography
            text={description}
            variant="subheading-regular"
            customClassName="description"
          />
        </div>
      </div>
    </StyledContainer>
  )
}

export default Responsive(MobileCategoryPaletteCard)
