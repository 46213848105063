import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 56px;
  max-width: 1124px;
  width: 100%;

  .heading-wrapper {
    padding: 0;
    margin: 0;
  }

  .heading-text {
    margin: 0 15px 10px 15px;
    display: block;
  }
  .subheading-wrapper {
    margin: 0 15px 20px 15px;
  }

  @media (min-width: 768px) {
    margin-bottom: 96px;

    .cards-wrapper {
      margin: auto;
      margin-top: 30px;
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: row;
      max-width: 1124px;
      width: 100%;
      gap: 15px;
    }
    .heading-wrapper {
      .heading-text {
        color: var(--brand-font-default-color);
        margin-bottom: 10px;
        width: 100%;
      }
    }

    .subheading-wrapper {
      margin-bottom: 12px;
      .subheading-text {
        color: var(--brand-primary-grey-color);
      }
    }
  }
`
