import React from 'react'
import MobileCategoryPaletteCard from './MobileCategoryPaletteCard'
import DesktopCategoryPaletteCard from './DesktopCategoryPaletteCard'
import Responsive from '../../../../HOC/Responsive'

function CategoryPaletteCard({state = {}, actions = {}, isMobile}) {
  return (
    <>
      {isMobile ? (
        <MobileCategoryPaletteCard state={state} actions={actions} />
      ) : (
        <DesktopCategoryPaletteCard state={state} actions={actions} />
      )}
    </>
  )
}

export default Responsive(CategoryPaletteCard)
