import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin: 0 15px 16px 15px;
  display: flex;
  padding-bottom: 0;
  background-color: ${(props) => props.bgColor};
  border: 1px solid;
  border-color: ${(props) => props.cardBorderColor};
  box-sizing: ${(props) => props.cardShadow};
  flex-direction: ${(props) =>
    props.imageAlign === 'right' ? 'row' : 'row-reverse'};
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;

  .content-container {
    display: flex;
    flex-direction: column;
    font-family: var(--brand-font-family-text);
    color: var(--brand-font-default-color);
    font-style: normal;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
    width: 60%;
    padding: ${(props) =>
      props.imageAlign === 'right' ? '0 5px 0 5px' : '0 5px 0 15px'};

    .categoryName {
      display: inline;
      margin-right: 14px;
    }
    .description {
      color: var(--brand-light-secondary-text-color);
    }

    .font-awesome-icon {
      font-weight: var(--brand-font-weight-medium);
      color: var(--brand-primary-black-color);
    }
  }

  .image-container {
    width: 40%;
    height: 100%;
    object-fit: contain;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: ${(props) => props.imageBgColor};
    border-radius: ${(props) =>
      props.imageAlign === 'right' ? '20px 0 0 20px' : '0 20px 20px 0'};
    padding: 10px 12px;
    padding-bottom: 0;
    .card-image {
      width: 120px;
      height: 140px;
      height: auto;
    }
  }
`
